import React, { useEffect, useState, useRef } from 'react';
import { useOktaAuth } from "@okta/okta-react";
import { jwtDecode } from "jwt-decode";
import { WppButton, WppCard, WppSpinner, WppToastContainer } from '@wppopen/components-library-react';
import Header from "../../components/Header";
import SideBar from "../../components/SideBar";
import GrowthPotential from './components/RPM/GrowthPotential';
import RetailerMaturity from './components/RPM/RetailerMaturity';
import Scatterplot from './components/RPM/Scatterplot';
import Datagrid from './components/RPM/DataGrid';
import DropSelectWppOpen from '../../components/DropSelectWppOpen';
import DropSelectWppOpenByID from '../../components/DropSelectWppOpenByID';
import DownloadIcon from '../assets/Download';
import { CSVLink } from "react-csv";
import Zoom from '../assets/Zoom';
import Pan from '../assets/Pan';
import './components/RPM/card.css';

export const useToast = () => {
  const [toastRef, setToastRef] = useState();

  const showToast = (config) => {
    toastRef?.addToast(config)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setToastRef(document.querySelector('#wpp-toast-container'))
  })

  return {
    showToast,
  }
}

const baseApi = process.env.REACT_APP_API_ENDPOINT;

const FLOW_SCORECARD = {
  'TRUE': true,
  'FALSE': false
}

const RetailPrioritizationMatrix = (props) => {

  const [markets, setMarkets] = useState([]);
  const [selectedMarkets, setSelectedMarkets] = useState([]);
  const [similarWebStatus, setSimilarWebStatus] = useState(false);
  const [eCommerceDBStatus, setECommerceDBStatus] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState(null);
  const [rfis, setRFIs] = useState([]);
  const [selectedFlowSetId, setSelectedFlowSetId] = useState(null);
  const [channels, setChannels] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [updateViewButtonDisabledStatus, setUpdateViewButtonDisabledStatus] = useState(true);
  const [data, setData] = useState([]);
  const [dataORIG, setDataORIG] = useState([]);
  const [downloadData, setDownloadData] = useState([]);
  const [filteredRetailers, setFilteredRetailers] = useState([]);
  const [spinnerVisible, setSpinnerVisible] = useState(true);
  const [submitToggle, setSubmitToggle] = useState(false);

  const { oktaAuth } = useOktaAuth();
  const accessToken = oktaAuth.getIdToken();
  const decodedToken = jwtDecode(accessToken);
  const userPermissions = decodedToken.groupsFusion;

  const csvLinkRef = useRef();
  
  const { showToast } = useToast()

  const apiGetHeader = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Authorization': `Bearer ${accessToken}` },
  };

  const getMarketsCategoriesFlows = () => {
    Promise.all([
      fetch(`${baseApi}/insights/v2/rpm/markets_by_datasource`, apiGetHeader),
      fetch(`${baseApi}/insights/v2/rpm/fusion_categories`, apiGetHeader),
      fetch(`${baseApi}/flow/fetch_flows`, apiGetHeader)
    ])
    .then(results => Promise.all(results.map(r => r.json())))
    .then(results => {
      setSpinnerVisible(false);
      setMarkets(results[0].sort((a,b) => (a.name > b.name) ? 1 : (b.name > a.name ? -1 : 0)));
      setCategories(results[1].sort((a,b) => (a.name > b.name) ? 1 : (b.name > a.name ? -1 : 0)));
      const rfiResults = results[2]?.data.sort((a,b) => (a.title > b.title) ? 1 : (b.title > a.title ? -1 : 0));
      rfiResults.unshift({ title: 'Fusion Scorecard', flowSetId: -1 });
      setRFIs(rfiResults);
    })
    .catch(e => {
      setSpinnerVisible(false);
      console.log('API FETCH ERROR: ', e)
    })
  }

  const getChannels = (isFlowScorecard) => {
    if (isFlowScorecard) {
      setChannels(['Audio', 'CTV', 'Off-Property Display', 'Off-Property Search', 'On-Property Display', 'On-Property Search', 'Social']);
    } else {
      setSpinnerVisible(true);
      fetch(`${baseApi}/flow/${selectedFlowSetId}/channels`, apiGetHeader)
      .then(response => response.json())
      .then(results => {
        setSpinnerVisible(false);
        setChannels(results?.data.map(a => a.channel));
      })
      .catch((error) => {
        console.log('API FETCH ERROR: ', error);
        setSpinnerVisible(false);
      })
    }
  }

  useEffect(() => {
    getMarketsCategoriesFlows();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (selectedFlowSetId === null) {
      return;
    } else if (selectedFlowSetId === -1) {  // Flow Scorecard
      getChannels(FLOW_SCORECARD.TRUE)
    } else {
      getChannels(FLOW_SCORECARD.FALSE)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFlowSetId])

  useEffect(() => {
    if (selectedCategories && selectedChannels.length && (similarWebStatus || eCommerceDBStatus)) {
      setUpdateViewButtonDisabledStatus(false);
    } else {
      setUpdateViewButtonDisabledStatus(true);
    }
  }, [selectedCategories, selectedChannels, similarWebStatus, eCommerceDBStatus])

  useEffect(() => {
    if (filteredRetailers.length) {
      setData([...dataORIG].filter(a => filteredRetailers.includes(a.retailer + ' ' + a.market)));
    } else {
      setData([...dataORIG])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredRetailers])

  const transformDownloadData = (data) => {
    const data_to_download = [];
    data?.forEach(a => {
      data_to_download.push({ Retailer: a.retailer, Market: a.market,  "Fusion Score": a.fusion_score, "ECDB Revenue": a.ecdb_revenue, "SimilarWeb Traffic": a.similarweb_traffic })
    })
    return data_to_download;
  }

  const transformChartData = (data) => {
    const chartData = [];
    if (data?.length) {
      data.forEach(a => {
        if (a.fusion_score !== null
            && a.fusion_score !== undefined
            && a.growth_potential !== null
            && a.growth_potential !== undefined
            && a.growth_potential !== "") {
              chartData.push({ name: `${a.retailer} ${a.market}`, x: a.fusion_score / 100, y: +a.growth_potential, logoPath: a.logoPath })
        }
      })
    }
    return chartData;
  }

  const transformGridData = (data) => {
    const formatter = Intl.NumberFormat("en-US", { notation: "compact", minimumFractionDigits: 2, maximumFractionDigits: 2});
    const gridData = [];
    data.forEach(a => {
      gridData.push({ ...a,
        fusion_score: Math.round(a.fusion_score),
        ecdb_revenue: a.ecdb_revenue === null || a.ecdb_revenue === 0 ? 'N/A' : a.ecdb_revenue,
        similarweb_traffic: a.similarweb_traffic === null || a.similarweb_traffic === 0 ? 'N/A' : formatter.format(a.similarweb_traffic)
      })
    })
    return gridData;
  }

  const submit = async () => {

    setFilteredRetailers([]);

    const categoryObjects = categories.filter(a => a.id === selectedCategories);
    const formattedCategories = [];
    categoryObjects.forEach(a => formattedCategories.push({ id: a.id, category: a.name }))

    const selectedPlatform = [];
    if (similarWebStatus) selectedPlatform.push("similarweb");
    if (eCommerceDBStatus) selectedPlatform.push("ecommdb");

    const payload = {
      markets: selectedMarkets,
      channels: selectedChannels,
      categories: formattedCategories,
      platforms: selectedPlatform,
      flowSetId: selectedFlowSetId,
    }

    setSpinnerVisible(true);
    fetch(`${baseApi}/insights/v2/rpm?fusionscorecard=${selectedFlowSetId === -1 ? 1 : 0}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Authorization': `Bearer ${accessToken}` },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        setSpinnerVisible(false);
        setSubmitToggle(!submitToggle);

        var marketsObj = markets.reduce((obj, item) => { 
          obj[item.id] = item.name; 
          return obj
        }, {});
        data.rows?.forEach(a => a["market"] = marketsObj[a.marketId]);
        const sortedData = data.rows?.sort((a,b) => (a.retailer > b.retailer) ? 1 : (b.retailer > a.retailer ? -1 : 0)) ?? [];
        setData(sortedData.length ? sortedData : []);
        setDataORIG(sortedData.length ? [...sortedData] : []);
        setDownloadData(transformDownloadData(sortedData));
      })
      .catch((error) => {
        console.log('API FETCH ERROR: ', error);
        setData({});
        setDataORIG({});
        setDownloadData([]);
        setSpinnerVisible(false);
      });
  };

  const download = (event) => {
    if (!downloadData.length) return;

    csvLinkRef.current.link.click();
    showToast({
      type: 'success',
      header: 'Download successful',
      duration: 4000,
    })
  }

  return (
    <>
      {spinnerVisible && (
        <div className="mainSpinner ml-4">
          <div>
            <WppSpinner size="l" />
          </div>
        </div>
      )}
      <WppToastContainer maxToastsToDisplay={5} id="wpp-toast-container" />
      <Header title="" />
      <SideBar active={"insights"} userPermissions={userPermissions} />
      <div className="w-full h-[1600px] bg-gray-50">
        <div className="ml-[240px]">
          <div className="text-[28px] pt-6 font-semibold text-[#121619]">
            Retail Prioritization Matrix
          </div>
          <div className="text-sm font-normal text-[#4D5358] w-96 mt-2">
            A visualization matrix to support the evolving world of retail media, shedding light on where a brand in a specific category should prioritize their dollars.
          </div>
          <div className="text-base font-semibold mt-8">
            Data Source
          </div>
          <div className="text-sm font-normal text-[#4D5358] w-96">
            Build your chart by selecting data for each axis.
          </div>
          <div className="w-[310px] mt-3 ml-1">
            <DropSelectWppOpenByID 
              type="multiple"
              title="Market"
              className="w-72 text-[14px]"
              items={markets}
              id="id"
              value="name"
              selection={selectedMarkets}
              setSelection={setSelectedMarkets}
              placeholder="Select Market"
            />
          </div>
          <div className="flex mt-4">
            <div>
              <GrowthPotential
                similarWebStatus={similarWebStatus}
                setSimilarWebStatus={setSimilarWebStatus}
                eCommerceDBStatus={eCommerceDBStatus}
                setECommerceDBStatus={setECommerceDBStatus}
                categories={categories}
                selectedCategories={selectedCategories}
                setSelectedCategories={setSelectedCategories}
              />
            </div>
            <div className="ml-3">
              <RetailerMaturity
                rfis={rfis}
                selectedFlowSetId={selectedFlowSetId}
                setSelectedFlowSetId={setSelectedFlowSetId}
                channels={channels}
                setChannels={setChannels}
                selectedChannels={selectedChannels}
                setSelectedChannels={setSelectedChannels}
              />
            </div>
          </div>
          <div className="w-[1300px] flex justify-end mt-3">
            <WppButton variant="primary" size="m" onClick={submit} disabled={updateViewButtonDisabledStatus}>
              Update View
            </WppButton>
          </div>
          <div className="mt-8">
            <WppCard
              size={'4xl'}
              style={{ width: '1300px', height: '780px', marginBottom: '40px' }}
              className="CARD"
            >
              <div className="pt-6 flex justify-between items-center">
                <div className="w-[225px] ml-6">
                  <DropSelectWppOpen 
                    type="multiple"
                    className="w-72 text-[14px]"
                    items={dataORIG.length ? dataORIG?.map(a => `${a.retailer} ${a.market}`).sort() : null}
                    selection={filteredRetailers}
                    setSelection={setFilteredRetailers}
                    placeholder="Filter Retailers"
                  />
                </div>
                <div className="flex items-center" onClick={download} style={{ cursor: "pointer" }}>
                  <DownloadIcon />
                  <span className="text-sm font-semibold text-[#0014CC] ml-2 mr-9">
                    Download Data
                  </span>
                </div>
              </div>
              <div className="flex ml-6 mt-5">
                <Zoom /><span className="text-sm font-semibold ml-1 mr-2">Zoom: Scroll/Pinch</span><Pan /><span className="text-sm font-semibold ml-1">Pan: Click and Drag</span>
              </div>
              <div className="flex ml-6">
                <CSVLink
                  data={downloadData}
                  filename="data.csv"
                  className="hidden"
                  ref={csvLinkRef}
                  target="_blank"
                />
                <Scatterplot data={transformChartData(data)} submitToggle={submitToggle} filteredRetailers={filteredRetailers} width={637} height={590} />
                <Datagrid data={transformGridData(data)} />
              </div>
            </WppCard>
          </div>
        </div>
      </div>
    </>
  );
}
export default RetailPrioritizationMatrix;