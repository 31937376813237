import React from 'react';
import { WppCard } from '@wppopen/components-library-react';
import ArrowsHorizontal from '../../../assets/ArrowsHorizontal';
import DropSelectWppOpen from '../../../../components/DropSelectWppOpen';
import { WppSelect, WppListItem } from "@wppopen/components-library-react";
import './card.css';

const RetailerMaturity = (props) => {

  const {
    rfis,
    selectedFlowSetId,
    setSelectedFlowSetId,
    channels,
    setChannels,
    selectedChannels,
    setSelectedChannels} = props;

  return (
    <WppCard
      size={'2xl'}
      style={{ width: '641px', height: '330px' }}
      className="CARD"
    >
      <div className="ml-[-20px] mt-[-10px]">
        <ArrowsHorizontal />
      </div>
      <div className="ml-[-20px] text-base font-semibold mt-2 flex items-center">
        Retailer Maturity
      </div>
      <div className="ml-[-20px] text-sm font-normal text-[#4D5358] w-[580px]">
        The paid and owned media maturity of the retailer in a chosen market based on Flow results.​
      </div>
      <hr className="ml-[-20px] divide-y-2 divide-solid divide-gray-700 mt-3" />
      <div className="ml-[-20px] mt-3">
        <div className="flex justify-between mt-2 w-[600px]">
          <div className="w-[300px]">
            <WppSelect
              placeholder={selectedFlowSetId?.length ? selectedFlowSetId : "RFI"}
              type={"single"}
              labelConfig={{ text: "RFI" }}
              value={selectedFlowSetId}
              onWppChange={e => {
                setChannels([]);
                setSelectedChannels([]);
                setSelectedFlowSetId(e.detail.value)
              }}
              withSearch={true}
              withFolder={true}
              className="w-72 text-[14px]"
              required
            >
              {rfis?.length > 0 && rfis.map((item, index) => {
                return (
                  <WppListItem
                    key={index}
                    value={item["flowSetId"]}
                  >
                    <p className="w-72 text-[14px]" slot="label">{item["title"]}</p>
                  </WppListItem>
                )
              })}
            </WppSelect>
          </div>
          <div className="w-[300px]">
            <DropSelectWppOpen 
              type="multiple"
              title="Channels"
              className="w-72 text-[14px]"
              items={channels}
              selection={selectedChannels}
              setSelection={setSelectedChannels}
              placeholder="Channels"
            />
          </div>
        </div>
      </div>
    </WppCard>
  )
}
export default RetailerMaturity;