import React, { memo } from 'react';
import {
  DataGridPro,
  GridRow,
  GridColumnHeaders,
} from '@mui/x-data-grid-pro';
import { SortedDescendingIcon, SortedAscendingIcon, UnsortedIcon} from '../../utils'

const MemoizedRow = memo(GridRow);
const MemoizedColumnHeaders = memo(GridColumnHeaders);

export const columns = [
  {
    field: 'retailer',
    headerName: 'Retailer',
    width: 130,
    headerClassName: 'data-grid',
  },
  {
    field: 'market',
    headerName: 'Market',
    width: 90,
    headerClassName: 'data-grid',
  },
  {
    field: 'fusion_score',
    headerName: 'Fusion Score',
    width: 127,
    headerClassName: 'data-grid',
  },
  {
    field: 'ecdb_revenue',
    headerName: 'ECDB Revenue',
    headerClassName: 'data-grid',
    width: 140,
  },
  {
    field: 'similarweb_traffic',
    headerName: 'SimilarWeb Traffic',
    headerClassName: 'data-grid',
    width: 157,
  },
];

const DataGrid = (props) => {
  const { data } = props;

  return (
    <div className="h-[600px] w-[620px]">
      <DataGridPro
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            textTransform: 'none',
            fontSize: '12px',
            fontWeight: '600',
          },
          '& .MuiDataGrid-iconButtonContainer': {
            marginLeft: '-5px',
            visibility: 'visible !important',
            width: 'auto !important',
          },
          '& .MuiDataGrid-columnHeaderTitleContainer': {
            margin: '0'
          },
          '&, [class^=MuiDataGrid-footerContainer]': {
            borderTop: 'none'
          },
        }}
        columns={columns}
        rows={data ?? []}
        getRowId={(row) => row.retailer + row.market}
        pagination
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        pageSizeOptions={[10]}
        rowHeight={58}
        disableRowSelectionOnClick
        components={{
          Row: MemoizedRow,
          ColumnHeaders: MemoizedColumnHeaders,
        }}
        slotProps={{
          pagination: {
            showFirstButton: true,
            showLastButton: true,
          },
        }}
        // componentsProps={{
        //   panel: {
        //     sx: {
        //       top: '-165px !important',
        //     },
        //   },
        // }}
        disableColumnMenu={true}
        slots={{
          columnSortedDescendingIcon: SortedDescendingIcon,
          columnSortedAscendingIcon: SortedAscendingIcon,
          columnUnsortedIcon: UnsortedIcon,
        }}
      />
    </div>
  );
}
export default DataGrid;