import { QUADRANT_COLOR } from '../Scatterplot';

const Legend = ({ width }) => {
  return (
    <>
      <div>
        <svg width={width} height={50}>
          <g transform={`translate(${[10, 40].join(",")})`}>
            <circle
              opacity={1}
              fill={QUADRANT_COLOR[2]}
              r={6}
            />
            <text
              x={15}
              y={1}
              fontSize={12}
              fontWeight={500}
              textAnchor={"left"}         // horizontal alignment
              dominantBaseline={"middle"} // vertical alignment
            >
              Hight Retailer Maturity x High Growth Potential
            </text>
          </g>
          <g transform={`translate(${[width / 2 + 25, 40].join(",")})`}>
            <circle
              opacity={1}
              fill={QUADRANT_COLOR[1]}
              r={6}
            />
            <text
              x={15}
              y={1}
              fontSize={12}
              fontWeight={500}
              textAnchor={"left"}
              dominantBaseline={"middle"}
            >
              Low Retailer Maturity x High Growth Potential
            </text>
          </g>
        </svg>
      </div>
      <div>
        <svg width={width} height={50}>
          <g transform={`translate(${[10, 15].join(",")})`}>
            <circle
              opacity={1}
              fill={QUADRANT_COLOR[4]}
              r={6}
            />
            <text
              x={15}
              y={1}
              fontSize={12}
              fontWeight={500}
              textAnchor={"left"}
              dominantBaseline={"middle"}
            >
              Low Retailer Maturity x Low Growth Potential
            </text>
          </g>
          <g transform={`translate(${[width / 2 + 25, 15].join(",")})`}>
            <circle
              opacity={1}
              fill={QUADRANT_COLOR[3]}
              r={6}
            />
            <text
              x={15}
              y={1}
              fontSize={12}
              fontWeight={500}
              textAnchor={"left"}
              dominantBaseline={"middle"}
            >
              High Retailer Maturity x Low Growth Potential
            </text>
          </g>
        </svg>
      </div>
    </>
  );
}
export default Legend;